/*
 * @Description:
 * @Date: 2024-02-06 10:09:59
 * @LastEditTime: 2024-03-18 11:41:01
 */
import request from '@/router/axios';

// 获取登录验证码
export const postverificationCode = (data) => {
  return request.post('/api/user/sendSmsCode', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// 登录
export const getLogin = (data) => {
  return request.post('/api/user/login', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// 修改密码
export const getupdatePwd = (data) => {
  return request.post('/api/user/updatePwd', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const getRegister = (data) => {
  return request.post('/api/user/register', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// 获取账号关联信息
export const getRelevance = (data) => {
  return request.post('/api/user/getRelatePlatUserInfo', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const PostBindAishipgoAccount = (data) => {
  return request.post('/api/user/bindAishipgoAccount', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// 卖家大会专用注册接口
export const registerForSellerMeeting = (data) => {
  return request.post('/api/user/registerForSellerMeeting', data);
};
